import React from 'react';
import { SVG, SVGProps } from './SVG';

export const VoiceIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<path d="M9,13a5,5,0,0,0,5-5V5A5,5,0,0,0,4,5V8A5,5,0,0,0,9,13ZM5.2,5a3.8,3.8,0,0,1,7.6,0V8A3.8,3.8,0,0,1,5.2,8ZM15.4,6V8A6.4,6.4,0,0,1,2.6,8V6H1.4V8a7.61,7.61,0,0,0,7,7.57V16.8H5V18h8V16.8H9.6V15.57A7.61,7.61,0,0,0,16.6,8V6Z" />
		</g>
	</SVG>
);
