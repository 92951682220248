import React from 'react';
import { SendEvent } from 'utils/logging/types';
import { useTranslation } from 'utils/i18next';
import { Flex } from 'components/common';
import { FxPodcast } from './FxPodcast';
import { ResearchSection } from './ResearchSection';
import { ResearchHeading } from './ResearchHeading';

interface FxPodcastsProps {
	sendEvent?: SendEvent;
}

export const FxPodcasts = (props: FxPodcastsProps) => {
	// todo: add events tracking
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { sendEvent } = props;
	const { t } = useTranslation('research');

	const podcasts = [
		{
			name: 'The Macro Brief',
			link: 'https://www.gbm.hsbc.com/en-gb/feed/global-research/podcasts/macro-viewpoint'
		},
		{
			name: 'Under the Banyan Tree',
			link: 'https://www.gbm.hsbc.com/en-gb/feed/global-research/podcasts/macro-viewpoint'
		}
	];

	return (
		<ResearchSection>
			<ResearchHeading>{t('podcasts.heading')}</ResearchHeading>
			<p>{t('podcasts.description')} </p>
			<Flex flexDirection="column" gridGap="small">
				{podcasts.map(({ link, name }) => (
					<FxPodcast key={name} link={link} name={name} />
				))}
			</Flex>
			<Flex flexDirection="column" marginTop="large">
				<a
					href="https://www.gbm.hsbc.com/en-gb/feed/global-research/podcasts/macro-viewpoint"
					accessKey="a"
				>
					<img
						alt="Listen on Apple Podcasts"
						width="222"
						height="54"
						src="https://www.gbm.hsbc.com/-/jssmedia/media/gbm-global/images/campaign/apple.png?h=80&amp;iar=0&amp;w=333&amp;hash=DA19790F3D058BC241F58F6CFBA6735C"
					/>
				</a>
				<a
					href="https://www.gbm.hsbc.com/en-gb/feed/global-research/podcasts/macro-viewpoint"
					accessKey="s"
				>
					<img
						alt="Listen on Spotify"
						width="222"
						height="54"
						src="https://www.gbm.hsbc.com/-/jssmedia/media/gbm-global/images/campaign/spotify.png?h=80&amp;iar=0&amp;w=333&amp;hash=A67ED795293C0AFB8DAD094CCEBB8DB2"
					/>
				</a>
			</Flex>
		</ResearchSection>
	);
};
