import React, { useCallback, useEffect, useState } from 'react';
import { fetchFxRate as _fetchFxRate } from 'services/currency-zone';
import { registerRateAlert as _registerRateAlert } from 'services/rate-alerts';
import { splitCcyPair } from 'utils/currency';
import { useTranslation } from 'utils/i18next';
import { FxRate, FxRateAlertRegister, Range } from 'types';
import { Modal, ModalHeading } from 'components/modals';
import { Loader, Notification } from 'components/common';
import { CurrencyRateRange } from 'components/CurrencyRateRange';
import { RateAlertChart } from './RateAlertChart';
import { RateAlertForm } from './RateAlertForm';
import { RateAlertModalSubmitted } from './RateAlertModalSubmitted';

interface RateAlertModalProps {
	onClose: () => void;
	fxRate: FxRate;
	rateRange: Range;
	fetchFxRate?: typeof _fetchFxRate;
	registerFxRateAlert?: typeof _registerRateAlert;
}

export const RateAlertModal = (props: RateAlertModalProps) => {
	const {
		onClose,
		fxRate: initialFxRate,
		rateRange: initialRateRange,
		fetchFxRate = _fetchFxRate,
		registerFxRateAlert = _registerRateAlert
	} = props;
	const { t } = useTranslation();

	const [isFxRateLoading, setIsFxRateLoading] = useState<boolean>(false);
	const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

	const [ccyPair, setCcyPair] = useState<string>(initialFxRate.ccyPair);
	const [ccyFrom, ccyTo] = splitCcyPair(ccyPair);

	const [submitFormError, setSubmitFormError] = useState<boolean>(false);
	const [fetchFxRateError, setFetchFxRateError] = useState<boolean>(false);
	const [isAlertCreated, setIsAlertCreated] = useState<boolean>(false);

	const [fxRate, setFxRate] = useState<FxRate>(initialFxRate);
	const [rateRange, setRateRange] = useState<Range>(initialRateRange);
	const [rateAlertValue, setRateAlertValue] = useState<string>('');
	const [isAlertRateEqualCurrentRate, setIsAlertRateEqualCurrentRate] = useState<boolean>(false);
	const [isPayloadTooLarge, setIsPayloadTooLarge] = useState<boolean>(false);

	// todo: below logic should be moved to RateAlertForm component
	const compareWithCurrentRate = useCallback(
		(rateInput: string) => {
			const rate = parseFloat(rateInput);
			if (isNaN(rate) || !fxRate.latest?.open) {
				return false;
			}

			console.log(fxRate.latest.open);
			console.log(rate);

			return fxRate.latest.open === rate;
		},
		[fxRate]
	);

	useEffect(() => {
		setIsFxRateLoading(true);
		setFetchFxRateError(false);
		fetchFxRate(ccyFrom, ccyTo, rateRange)
			.then(rate => setFxRate(rate))
			.catch(() => setFetchFxRateError(true))
			.finally(() => setIsFxRateLoading(false));
	}, [ccyFrom, ccyTo, fetchFxRate, rateRange]);

	useEffect(() => {
		setIsAlertRateEqualCurrentRate(compareWithCurrentRate(rateAlertValue));
	}, [compareWithCurrentRate, rateAlertValue]);

	const handleFormSubmit = async (rateAlert: FxRateAlertRegister) => {
		setIsFormSubmitting(true);
		try {
			await registerFxRateAlert(rateAlert);
			setIsAlertCreated(true);
		} catch (e) {
			const error = e as { response?: { status?: number } };
			if (error.response && error.response.status === 413) {
				setIsPayloadTooLarge(true);
			} else {
				setSubmitFormError(true);
			}
		} finally {
			setIsFormSubmitting(false);
		}
	};

	return (
		<>
			{isAlertCreated ? (
				<RateAlertModalSubmitted onClose={onClose} />
			) : (
				<Modal maxWidth={'850px'}>
					<ModalHeading onClose={onClose}>
						{`${t('rateAlert.modal.heading')} ${fxRate.ccyPair}`}
					</ModalHeading>
					<>
						<CurrencyRateRange
							name="rate-alert-range"
							value={rateRange}
							onChange={setRateRange}
						/>
						<RateAlertChart
							fxRate={fxRate}
							isLoading={isFxRateLoading}
							onClick={setRateAlertValue}
							selectedRate={rateAlertValue}
						/>
						{submitFormError && (
							<Notification type="error">{t('errors.problemOccurred')}</Notification>
						)}
						{fetchFxRateError && (
							<Notification type="error">{t('errors.generic')}</Notification>
						)}
						{isAlertRateEqualCurrentRate && (
							<Notification type="warning">{t('errors.invalidRate2')}</Notification>
						)}
						{isPayloadTooLarge && (
							<Notification type="error">{t('errors.payload')}</Notification>
						)}
						<RateAlertForm
							fxRate={fxRate}
							ccyPair={ccyPair}
							rateAlertValue={rateAlertValue}
							submitBtnDisabled={isAlertRateEqualCurrentRate}
							onRateAlertValueChange={setRateAlertValue}
							onClose={onClose}
							onCcyPairChange={(ccyPair: string) => setCcyPair(ccyPair)}
							onSubmit={handleFormSubmit}
						/>
						{isFormSubmitting && <Loader />}
					</>
				</Modal>
			)}
		</>
	);
};
