import React from 'react';
import { styled, typography } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { ArticleFrequency, ArticleType } from 'types';
import { ReadIcon, RenewIcon, VideoIcon, VoiceIcon } from './icons';

const StyledBadge = styled.div`
	display: flex;
	align-items: flex-end;
	grid-gap: ${({ theme }) => theme.space.small};
	${typography.s7}
`;

interface ArticleBadgeProps {
	icon: React.ReactElement;
	label: string;
}

type ArticleBadges<T extends string> = {
	[icon in T]: ArticleBadgeProps;
};

const ArticleBadge = ({ icon, label }: ArticleBadgeProps) => (
	<StyledBadge>
		{icon}
		{label}
	</StyledBadge>
);

interface ArticleTypeBadgeProps {
	type: ArticleType;
}

export const ArticleTypeBadge = ({ type }: ArticleTypeBadgeProps) => {
	const { t } = useTranslation();

	const articleTypeBadge: ArticleBadges<ArticleType> = {
		article: {
			icon: <ReadIcon size="small" />,
			label: t('research.type.article')
		},
		podcast: {
			icon: <VoiceIcon size="small" />,
			label: t('research.type.podcast')
		},
		video: {
			icon: <VideoIcon size="small" />,
			label: t('research.type.video')
		}
	};

	return <ArticleBadge {...articleTypeBadge[type]} />;
};

interface ArticleFrequencyBadgeProps {
	frequency: ArticleFrequency;
}

export const ArticleFrequencyBadge = ({ frequency }: ArticleFrequencyBadgeProps) => {
	const { t } = useTranslation();

	const articleFrequencyBadge: ArticleBadges<ArticleFrequency> = {
		weekly: {
			icon: <RenewIcon size="small" />,
			label: t('research.frequency.weekly')
		},
		monthly: {
			icon: <RenewIcon size="small" />,
			label: t('research.frequency.monthly')
		}
	};

	return <ArticleBadge {...articleFrequencyBadge[frequency]} />;
};
