import { uniq } from 'lodash';
import { currencyCodes, currencyCodesG10, metalCodes } from 'common/currencies';
import { invertCcyPair, splitCcyPair } from 'utils/currency';
import localStorage from './local-storage-service';

const CURRENCY_ZONE_WATCH_LIST = 'CURRENCY_ZONE_WATCH_LIST';
const defaultState = ['GBPUSD', 'EURUSD', 'USDJPY'];

const getWatchlist = (): string[] => {
	const watchlist = localStorage.getItem(CURRENCY_ZONE_WATCH_LIST, defaultState);

	const validateCcyPair = (ccyPair: string): boolean => {
		const [ccyFrom, ccyTo] = splitCcyPair(ccyPair);
		const allowedCurrencies = [...currencyCodesG10, ...currencyCodes, ...metalCodes];

		return (
			allowedCurrencies.findIndex(ccy => ccy === ccyFrom) !== -1 &&
			allowedCurrencies.findIndex(ccy => ccy === ccyTo) !== -1
		);
	};

	return watchlist.filter(validateCcyPair);
};

const saveWatchlist = (favourites: string[]): void => {
	localStorage.setItem(CURRENCY_ZONE_WATCH_LIST, uniq(favourites));
};

const addToWatchlist = (ccyPair: string): void => {
	const listOfFavourites = getWatchlist();
	saveWatchlist([...listOfFavourites, ccyPair]);
};

const removeFromWatchlist = (ccyPair: string): void => {
	const listOfFavourites = getWatchlist();
	const updatedList = listOfFavourites.filter(el => el !== ccyPair);
	saveWatchlist(updatedList);
};

const invertWatchlistItem = (ccyPair: string): void => {
	const listOfFavourites = getWatchlist();
	const updatedList = listOfFavourites.map(fav => (fav !== ccyPair ? fav : invertCcyPair(fav)));
	saveWatchlist(updatedList);
};

export interface WatchlistService {
	get(storage?: Storage): string[];
	add(ccyPair: string): void;
	remove(ccyPair: string): void;
	invert(ccyPair: string): void;
	clear(): void;
	reset(): void;
	defaultState: string[];
}

const watchlistService: WatchlistService = {
	get: getWatchlist,
	add: addToWatchlist,
	remove: removeFromWatchlist,
	invert: invertWatchlistItem,
	clear: () => saveWatchlist([]),
	reset: () => saveWatchlist(defaultState),
	defaultState
};

export default watchlistService;
