import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'utils/i18next';
import { Header, Loader, Page } from 'components/common';
import { confirmFxRateAlertEmail as confirmFxRateAlertEmailDefault } from 'services/currency-zone-service';

interface RateAlertParams {
	token: string;
}

interface ConfirmEmailProps {
	confirmFxRateAlertEmail?: (token: string) => Promise<void>;
}

export const ConfirmEmail = (props: ConfirmEmailProps) => {
	const { confirmFxRateAlertEmail = confirmFxRateAlertEmailDefault } = props;
	const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
	const [isPending, setIsPending] = useState<boolean>(true);
	const { t } = useTranslation();

	const { token } = useParams<RateAlertParams>();

	useEffect(() => {
		(async () => {
			try {
				await confirmFxRateAlertEmail(token);
				setIsConfirmed(true);
			} catch (e) {
				// todo
			} finally {
				setIsPending(false);
			}
		})();
	}, [confirmFxRateAlertEmail, token]);

	return (
		<Page>
			<Helmet title="Confirm rate alert" />
			{isPending ? (
				<Loader textDelay={3000} />
			) : (
				<>
					<Header>
						<h1 className="page-description__heading">
							{isConfirmed
								? t('alerts.confirmEmail.success.heading')
								: t('alerts.confirmEmail.failure.heading')}
						</h1>
					</Header>
					{isConfirmed && (
						<>
							<p>{t('alerts.confirmEmail.success.confirmation')}</p>
							<p>{t('alerts.confirmEmail.success.notice')}</p>
						</>
					)}
				</>
			)}
		</Page>
	);
};
