import React, { useEffect, useState } from 'react';
import { FxRateAlert } from 'types';
import {
	fetchFxRateAlert as fetchRateAlertDefault,
	cancelFxRateAlert as cancelFxRateAlertDefault
} from 'services/currency-zone-service';
import { Helmet } from 'react-helmet-async';
import { Page, Loader, Header } from 'components/common';
import { useLocation, useParams } from 'react-router-dom';
import { parseQueryParams } from 'utils/url';
import { useTranslation } from 'utils/i18next';
import { CancelRateAlertForm } from './CancelRateAlertForm';

interface CancelRateAlertProps {
	fetchFxRateAlert?: (orderId: string, token: string) => Promise<FxRateAlert>;
	cancelFxRateAlert?: (orderId: string, token: string) => Promise<void>;
}

export const CancelRateAlert = (props: CancelRateAlertProps) => {
	const {
		fetchFxRateAlert = fetchRateAlertDefault,
		cancelFxRateAlert = cancelFxRateAlertDefault
	} = props;
	const [isPending, setIsPending] = useState(true);
	const [isError, setIsError] = useState(false);
	const [fxRateAlert, setFxRateAlert] = useState<FxRateAlert>();
	const location = useLocation();
	const { token } = parseQueryParams(location.search);
	const { orderId } = useParams<{ orderId: string }>();
	const { t } = useTranslation();

	useEffect(() => {
		if (!orderId || !token) {
			setIsPending(false);
			setIsError(true);
		} else {
			fetchFxRateAlert(orderId, token)
				.then(data => setFxRateAlert(data))
				.catch(() => setIsError(true))
				.finally(() => setIsPending(false));
		}
	}, [fetchFxRateAlert, orderId, token]);

	return (
		<Page>
			<Helmet title="Cancel rate alert" />
			{isPending ? (
				<Loader />
			) : (
				<>
					{isError || !orderId || !token || !fxRateAlert ? (
						<Header>
							<h1 className="page-description__heading">
								{t('rateAlert.cancel.error.heading')}
							</h1>
							<p>{t('rateAlert.cancel.error.additionalText')}</p>
						</Header>
					) : (
						<CancelRateAlertForm
							cancelFxRateAlert={cancelFxRateAlert}
							fxRateAlert={fxRateAlert}
							orderId={orderId}
							token={token}
						/>
					)}
				</>
			)}
		</Page>
	);
};
