import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import MarkdownIt from 'markdown-it';
import sprintf from 'i18next-sprintf-postprocessor';
import parse from 'html-react-parser';
import httpMiddleware from 'i18next-http-middleware';
import en from './locales/en';
import pl from './locales/pl';
import es from './locales/es';
import fr from './locales/fr';
import tr from './locales/tr';
import zh from './locales/zh-CN';
import zhHk from './locales/zh-HK';

export { useTranslation, Trans, I18nextProvider, useSSR } from 'react-i18next';
export { i18next };
export * from 'i18next';

export enum Language {
	ENGLISH = 'en-GB',
	POLISH = 'pl',
	SPANISH = 'es',
	FRENCH = 'fr',
	CHINESE = 'zh-CN',
	CHINESE_HK = 'zh-HK',
	TURKISH = 'tr'
}

export const defaultNS = 'translation';
export const resources = {
	'en-GB': en,
	'zh-CN': zh,
	'zh-HK': zhHk,
	pl: pl,
	es: es,
	fr: fr,
	tr: tr
};

const md = MarkdownIt({
	breaks: true,
	html: true
});

i18next
	.use(initReactI18next)
	.use(httpMiddleware.LanguageDetector)
	.use({
		name: 'markdownPostprocessor',
		type: 'postProcessor',
		process: (value: string) => parse(md.renderInline(value))
	})
	.use(sprintf)
	.init({
		fallbackLng: 'en-GB',
		postProcess: [`markdownPostprocessor`],
		supportedLngs: ['en-GB', 'es', 'fr', 'zh-CN', 'zh-HK', 'tr'],
		ns: ['translation', 'currencies', 'headerGB', 'headerHK'],
		defaultNS,
		resources
	});

i18next.services.formatter?.add(
	'countdown',
	(value: string, lng, { minDigits = 2 }: { minDigits: number }) =>
		parseInt(value).toLocaleString('translation-GB', {
			minimumIntegerDigits: minDigits,
			useGrouping: false
		})
);

i18next.on('languageChanged', lng => {
	document.documentElement.setAttribute('lang', lng);
});
