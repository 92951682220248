import { styled } from 'utils/styles';

// it's a button which looks like a link
export const ButtonLink = styled.button`
	cursor: pointer;
	border: none;
	background-color: transparent;
	text-decoration: underline;
	padding: 0;
	color: ${({ color }) => color};
	font-size: ${({ theme: { fontSize } }) => fontSize.small};
`;
