import React from 'react';
import { SVG, SVGProps } from './SVG';

export const PlayIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18" {...props}>
		<g id="controlPlay">
			<rect width="4" height="6" />
			<path d="M16 7.153L9 .151 2 7.153v1.696l6.4-6.4V18h1.2V2.449l6.4 6.4z"></path>
		</g>
	</SVG>
);
