import React from 'react';
import { SVG, SVGProps } from './SVG';

export const VideoIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<path d="M0,0V18H18V0ZM16.8,16.8H1.2V1.2H16.8ZM6,5.5v7L12.14,9Z" />
		</g>
	</SVG>
);
