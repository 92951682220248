import React from 'react';
import { currencyCodes, currencyCodesG10, metalCodes } from './common/currencies';
import { TealiumEvent, TrackingEvent } from './utils/logging/types';
import { i18n } from 'utils/i18next';

export enum Range {
	DAY = 'day',
	MONTH = 'month',
	WEEK = 'week',
	YEAR = 'year',
	TWO_YEARS = '2y'
}

export enum Day {
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6,
	SUNDAY = 7
}

export type ErrorCode =
	| 'UNEXPECTED_ERROR'
	| 'NETWORK_ERROR'
	| 'TOKEN_EXPIRED'
	| 'TOKEN_INVALID'
	| 'TOKEN_COULD_NOT_CONFIRM'
	| 'RATE_ALERT_INVALID_ID'
	| 'RATE_ALERT_NOT_FOUND'
	| 'RATE_ALERT_NOT_ACTIVE';

export type Currency =
	| (typeof currencyCodes)[number]
	| (typeof currencyCodesG10)[number]
	| (typeof metalCodes)[number];
export type CurrencyCodes = typeof currencyCodes | typeof currencyCodesG10 | typeof metalCodes;
export type CurrencyConfig = {
	[code in Currency]: {
		symbol: string;
		decimals?: number;
	};
};

export type RateValue = number;

export interface Rate {
	open: RateValue | null;
	timestamp: number;
}

export interface LatestRate extends Rate {
	invertedOpen: number | null;
}

export type RateAlertDirection = 'BUY' | 'SELL';

export interface FxRate {
	ccyPair: string;
	dataSet: Rate[];
	decimalPlaces: number;
	decimalPlacesForInverted: number;
	latest: LatestRate | null;
	range: Range;
	high: number | null;
	low: number | null;
}

export interface FxRateAlert {
	id: string;
	ccyPair: string;
	rate: number;
	status: string;
	createdAt: string;
	expiresAt: string | null;
	confirmedAt: string | null;
	triggeredAt: string | null;
	sentAt: string | null;
	canceledAt: string | null;
}

export interface FxRateAlertRegister {
	email: string;
	ccyPair: string;
	rate: number;
	side?: RateAlertDirection;
}

export interface QueryParams {
	ccyFrom: Currency | null;
	ccyTo: Currency | null;
	range: Range | null;
	amount: number | null;
	hsbc_source?: string;
}

export interface FormState {
	amount: number;
	ccyFrom: Currency | null;
	ccyTo: Currency | null;
}

export type FxRateMap = {
	[index: string]: FxRate & { isLoading?: boolean };
};

export type DataView = 'CHART' | 'TABLE';

interface Link {
	href: string;
	label: string;
}

export type ArticleFrequency = 'weekly' | 'monthly';
export type ArticleType = 'article' | 'podcast' | 'video';
export interface Article {
	title: string;
	text: string;
	image: string;
	link: {
		href: string;
		external: boolean;
	};
	trackingEvent: TrackingEvent;
	type: ArticleType;
	date?: string;
	frequency?: ArticleFrequency;
}

interface NavBarMenuLink extends Link {
	pid: string;
}

export interface NavBarMenuItem {
	imgUrl: string;
	title: string;
	paragraph1: string;
	paragraph2: string;
	link: NavBarMenuLink;
	caption: string;
	trackingEvent: TealiumEvent;
}

export type UserLocation = 'UK' | 'HK' | 'OTHER';
export type HeaderRegion = UserLocation;
export type HeaderContext = 'personal' | 'business';
export type BankingRelationship = HeaderContext;

export type Env = 'dev' | 'uat' | 'prd';
export type EnvGroup = 'currencyzone-internal' | 'currencyzone-external';
export type FeatureToggleEnv =
	| 'currencyzone-internal-dev'
	| 'currencyzone-external-dev'
	| 'currencyzone-internal-uat'
	| 'currencyzone-external-uat'
	| 'currencyzone-internal-prd'
	| 'currencyzone-external-prd';

export interface InitialState {
	rate?: FxRate;
	query: QueryParams;
	i18n: i18n;
	env: Env;
	envGroup: EnvGroup;
}

declare global {
	// Important note: variable must be declared as var.
	// let or const variables doesn't show up on globalThis.
	// eslint-disable-next-line no-var
	var initial_state: InitialState;
}

export type MenuItem = {
	label: string;
	key?: string;
	href?: string;
	onClick?: () => void;
	isActive?: boolean;
	submenu?: MenuItem[];
	trackingEvent?: TealiumEvent;
	icon?: React.ReactNode;
};

export interface BankingSolution {
	imgUrl: string;
	title: string;
	paragraph1: string;
	paragraph2: string;
	link: {
		pid: string;
		href: string;
		label: string;
	};
	caption: string;
	trackingEvent: TealiumEvent;
}

export type FxTrend = 'up' | 'down' | 'sideways';
export type FxTrendsCcy = 'USD' | 'EUR' | 'GBP' | 'JPY' | 'CHF' | 'CAD' | 'AUD' | 'NZD';
export type FxTrendsCcyDirections = {
	[ccy in FxTrendsCcy]: {
		current: FxTrend;
		previous: FxTrend;
	};
};
