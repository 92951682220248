import React from 'react';
import { css, styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { Flex } from 'components/common';
import { PlayIcon } from 'components/icons';
import { FxTrend, FxTrendsCcy } from 'common/research';

interface FxTrendsShortTermBoxProps {
	current: FxTrend;
	previous: FxTrend;
	ccy: FxTrendsCcy;
}

type FxTrendsMap = {
	[key in FxTrend]: string;
};

const ShortTermBoxContainer = styled.div(
	({ theme: { space, colors } }) => css`
		display: flex;
		align-items: center;
		gap: ${space.large};
		padding: ${space.medium};
		background: ${colors.grey1};
	`
);

const ShortTermBoxCurrency = styled.div`
	font-size: 2.036rem;
`;

const ShortTermBoxHeading = styled.div`
	span {
		font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
	}
`;

const ShortTermTrendIcon = styled(PlayIcon)<{ trend: FxTrend }>(({ theme: { colors }, trend }) => {
	const colorsMap: FxTrendsMap = {
		up: colors.rag.green,
		down: colors.rag.red,
		sideways: colors.grey4
	};

	return css`
		fill: ${colorsMap[trend]};
		transform: ${trend === 'sideways'
			? 'rotate(90deg)'
			: trend === 'up'
				? 'rotate(0deg)'
				: 'rotate(180deg)'};
	`;
});

export const FxTrendsShortTermBox = (props: FxTrendsShortTermBoxProps) => {
	const { current, previous, ccy } = props;
	const { t } = useTranslation();

	return (
		<ShortTermBoxContainer>
			<ShortTermBoxCurrency>{ccy}</ShortTermBoxCurrency>
			<Flex flexDirection="column" gridGap="10px">
				<ShortTermBoxHeading>
					{t('researchNew.fxTrends.shortTermDirection')} <span>DXY^</span>
				</ShortTermBoxHeading>
				<Flex alignItems="center" justifyContent="space-between" gridGap="6px">
					<Flex gridGap="6px" alignItems="center">
						{t('researchNew.fxTrends.current')}:
						<ShortTermTrendIcon size="medium" trend={current} />
					</Flex>
					<Flex gridGap="6px" alignItems="center">
						{t('researchNew.fxTrends.previous')}:
						<ShortTermTrendIcon size="medium" trend={previous} />
					</Flex>
				</Flex>
			</Flex>
		</ShortTermBoxContainer>
	);
};
