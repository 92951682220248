import React from 'react';
import { debounce } from 'lodash';
import { RadioButton, RadioGroup } from 'components/form';
import { FxTrendsCcy, fxTrendsCcy } from 'common/research';
import { styled, visuallyHidden } from 'utils/styles';
import { useTranslation } from 'utils/i18next';

interface FxTrendsCurrenciesProps {
	value: FxTrendsCcy;
	onChange: (value: FxTrendsCcy) => void;
}

const HiddenLegend = styled.legend(visuallyHidden);

const buttons = fxTrendsCcy.map(ccy => ({ label: ccy, value: ccy }));

export const FxTrendsCurrencies = (props: FxTrendsCurrenciesProps) => {
	const { value, onChange } = props;
	const { t } = useTranslation();
	const handleChange = debounce(onChange, 100);

	return (
		<RadioGroup>
			<HiddenLegend>{t('timeRangeLegend')}</HiddenLegend>
			{buttons.map(button => (
				<RadioButton
					id={button.value}
					label={button.label}
					key={button.value}
					value={button.value}
					name="research-component-button"
					onChange={() => handleChange(button.value)}
					checked={value === button.value}
				/>
			))}
		</RadioGroup>
	);
};
