import { useState, useEffect } from 'react';
import { BankingRelationship, BankingSolution, UserLocation } from 'types';
import { getEnv, getStaticFileURL } from 'utils/url';
import { EVENTS } from 'utils/logging/types';
import { useHeaderTranslation } from 'hooks';

type Solutions = {
	[relationship in BankingRelationship]: {
		[location in UserLocation]: BankingSolution[];
	};
};

function assignPIDParamsToLinks(menu: BankingSolution[]): BankingSolution[] {
	const env = getEnv();

	return menu.map(({ link, ...menuItem }) => {
		const url = new URL(link.href);

		if (env === 'prd') {
			url.searchParams.append('pid', link.pid);
		}

		return {
			...menuItem,
			link: {
				...link,
				href: url.toString()
			}
		};
	});
}

export const useBankingSolutions = (
	bankingRelationship: BankingRelationship,
	userLocation: UserLocation
): BankingSolution[] => {
	const t = useHeaderTranslation(userLocation);
	const [solutions, setSolutions] = useState<BankingSolution[]>([]);

	useEffect(() => {
		const solutions: Solutions = {
			personal: {
				GB: [
					{
						imgUrl: getStaticFileURL('assets/header/personal_current_accounts.jpg'),
						title: t('personal.menu.currentAccounts.title'),
						paragraph1: t('personal.menu.currentAccounts.paragraph1'),
						paragraph2: t('personal.menu.currentAccounts.paragraph2'),
						link: {
							href: `https://www.hsbc.co.uk/current-accounts/products/`,
							label: t('personal.menu.currentAccounts.linkLabel'),
							pid: 'HBUK:MC:39:PS:FE:HMP:0123:001:CZ:'
						},
						caption: t('personal.menu.currentAccounts.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_PERSONAL_CURRENT_ACCOUNTS
					},
					{
						imgUrl: getStaticFileURL('assets/header/personal_currency_account.jpg'),
						title: t('personal.menu.international.title'),
						paragraph1: t('personal.menu.international.paragraph1'),
						paragraph2: t('personal.menu.international.paragraph2'),
						link: {
							href: `https://www.hsbc.co.uk/international/`,
							label: t('personal.menu.international.linkLabel'),
							pid: 'HBUK:MC:39:PS:FE:HMP:0123:002:CZ:'
						},
						caption: t('personal.menu.international.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_PERSONAL_CURRENCY_ACCOUNT
					},
					{
						imgUrl: getStaticFileURL('assets/header/personal_travel_money.jpg'),
						title: t('personal.menu.travelMoney.title'),
						paragraph1: t('personal.menu.travelMoney.paragraph1'),
						paragraph2: t('personal.menu.travelMoney.paragraph2'),
						link: {
							href: `https://www.hsbc.co.uk/international/travel-money/`,
							label: t('personal.menu.travelMoney.linkLabel'),
							pid: 'HBUK:MC:39:PS:FE:HMP:0123:003:CZ:'
						},
						caption: t('personal.menu.travelMoney.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_PERSONAL_TRAVEL_MONEY
					},
					{
						imgUrl: getStaticFileURL('assets/header/personal_hsbc_expat.jpg'),
						title: t('personal.menu.hsbcExpat.title'),
						paragraph1: t('personal.menu.hsbcExpat.paragraph1'),
						paragraph2: t('personal.menu.hsbcExpat.paragraph2'),
						link: {
							href: `https://www.expat.hsbc.com/accounts/products/bank-account/`,
							label: t('personal.menu.hsbcExpat.linkLabel'),
							pid: 'HBUK:MC:39:PS:FE:HMP:0123:004:CZ:'
						},
						caption: t('personal.menu.hsbcExpat.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_PERSONAL_BANK_ACCOUNT
					},
					{
						imgUrl: getStaticFileURL('assets/header/personal_anything_else.jpg'),
						title: t('personal.menu.anythingElse.title'),
						paragraph1: t('personal.menu.anythingElse.paragraph1'),
						paragraph2: t('personal.menu.anythingElse.paragraph2'),
						link: {
							href: `https://www.hsbc.co.uk/`,
							label: t('personal.menu.anythingElse.linkLabel'),
							pid: 'HBUK:MC:39:PS:FE:HMP:0123:005:CZ:'
						},
						caption: t('personal.menu.anythingElse.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_PERSONAL_HSBC_UK
					}
				],
				HK: [], // there is no personal menu for HK
				OTHER: []
			},
			business: {
				GB: [
					{
						imgUrl: getStaticFileURL('assets/header/business_accounts.jpg'),
						title: t('business.menu.businessAccounts.title'),
						paragraph1: t('business.menu.businessAccounts.paragraph1'),
						paragraph2: t('business.menu.businessAccounts.paragraph2'),
						link: {
							href: `https://www.business.hsbc.uk/en-gb/products-and-solutions/business-accounts`,
							label: t('business.menu.businessAccounts.linkLabel'),
							pid: 'HBUK:MC:39:PS:FE:HMP:0123:007:CZ:'
						},
						caption: t('business.menu.businessAccounts.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_ACCOUNTS
					},
					{
						imgUrl: getStaticFileURL('assets/header/business_hsbc_kinetic.jpg'),
						title: t('business.menu.hsbcKinetic.title'),
						paragraph1: t('business.menu.hsbcKinetic.paragraph1'),
						paragraph2: t('business.menu.hsbcKinetic.paragraph2'),
						link: {
							href: `https://www.business.hsbc.uk/en-gb/products/kinetic`,
							label: t('business.menu.hsbcKinetic.linkLabel'),
							pid: 'HBUK:MC:39:PS:FE:HMP:0123:008:CZ:'
						},
						caption: t('business.menu.hsbcKinetic.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_KINETIC
					},
					{
						imgUrl: getStaticFileURL('assets/header/business_global_wallet.jpg'),
						title: t('business.menu.hsbcGlobalWallet.title'),
						paragraph1: t('business.menu.hsbcGlobalWallet.paragraph1'),
						paragraph2: t('business.menu.hsbcGlobalWallet.paragraph2'),
						link: {
							href: `https://www.business.hsbc.uk/en-gb/corporate/solutions/global-wallet`,
							label: t('business.menu.hsbcGlobalWallet.linkLabel'),
							pid: 'HBUK:MC:39:PS:FE:HMP:0123:009:CZ:'
						},
						caption: t('business.menu.hsbcGlobalWallet.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_GLOBAL_WALLET
					},
					{
						imgUrl: getStaticFileURL('assets/header/business_anything_else.jpg'),
						title: t('business.menu.anythingElse.title'),
						paragraph1: t('business.menu.anythingElse.paragraph1'),
						paragraph2: t('business.menu.anythingElse.paragraph2'),
						link: {
							label: t('business.menu.anythingElse.linkLabel'),
							href: `https://www.business.hsbc.uk/`,
							pid: 'HBUK:MC:39:PS:FE:HMP:0123:010:CZ:'
						},
						caption: t('business.menu.anythingElse.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_HSBC_UK
					}
				],
				HK: [
					{
						imgUrl: getStaticFileURL('assets/header/hk_business_debit_card.jpg'),
						title: t('business.menu.realTimeFX.title'),
						paragraph1: t('business.menu.realTimeFX.paragraph1'),
						paragraph2: t('business.menu.realTimeFX.paragraph2'),
						link: {
							href: t('business.menu.realTimeFX.href'),
							label: t('business.menu.realTimeFX.linkLabel'),
							pid: 'HBHK:MC:39:PS:FE:HMP:0523:008:CZ:'
						},
						caption: t('business.menu.realTimeFX.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_GLOBAL_WALLET
					},
					{
						imgUrl: getStaticFileURL(
							'assets/header/hk_business_integrated_accounts.jpg'
						),
						title: t('business.menu.businessAccounts.title'),
						paragraph1: t('business.menu.businessAccounts.paragraph1'),
						paragraph2: t('business.menu.businessAccounts.paragraph2'),
						link: {
							href: t('business.menu.businessAccounts.href'),
							label: t('business.menu.businessAccounts.linkLabel'),
							pid: 'HBHK:MC:39:PS:FE:HMP:0523:006:CZ:'
						},
						caption: t('business.menu.businessAccounts.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_ACCOUNTS
					},
					{
						imgUrl: getStaticFileURL('assets/header/hk_business_merchant_box.jpg'),
						title: t('business.menu.merchantBox.title'),
						paragraph1: t('business.menu.merchantBox.paragraph1'),
						paragraph2: t('business.menu.merchantBox.paragraph2'),
						link: {
							href: t('business.menu.merchantBox.href'),
							label: t('business.menu.merchantBox.linkLabel'),
							pid: 'HBHK:MC:39:PS:FE:HMP:0523:007:CZ:'
						},
						caption: t('business.menu.merchantBox.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_KINETIC
					},
					{
						imgUrl: getStaticFileURL('assets/header/hk_business_real_time_fx.jpg'),
						title: t('business.menu.businessDebitCard.title'),
						paragraph1: t('business.menu.businessDebitCard.paragraph1'),
						paragraph2: t('business.menu.businessDebitCard.paragraph2'),
						link: {
							href: t('business.menu.businessDebitCard.href'),
							label: t('business.menu.businessDebitCard.linkLabel'),
							pid: 'HBHK:MC:39:PS:FE:HMP:0523:009:CZ:'
						},
						caption: t('business.menu.businessDebitCard.caption'),
						trackingEvent: EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_GLOBAL_WALLET
					}
				],
				OTHER: []
			}
		};

		setSolutions(assignPIDParamsToLinks(solutions[bankingRelationship][userLocation]));
	}, [bankingRelationship, userLocation, t]);

	return solutions;
};
