import { flex, FlexProps } from 'styled-system';
import { css, styled } from 'utils/styles';

export const ResearchSection = styled.section<FlexProps>(
	({ theme: { space } }) => css`
		margin-bottom: ${space.xlarge};
		width: 100%;
		& > p {
			text-align: justify;
		}
	`,
	flex
);
