import { HeaderRegion } from 'types';
import { useTranslation } from 'utils/i18next';

function getHeaderNameSpace(region: HeaderRegion) {
	switch (region) {
		case 'GB':
			return 'headerGB';
		case 'HK':
			return 'headerHK';
		default:
			return undefined;
	}
}

export const useHeaderTranslation = (region: HeaderRegion) => {
	const { t } = useTranslation(getHeaderNameSpace(region));

	return t;
};
