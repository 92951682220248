import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Header } from 'components/common';
import { formatDate } from 'utils/date';
import { useTranslation } from 'utils/i18next';
import { FxRateAlert } from 'types';

interface RateAlertCancelFormProps {
	cancelFxRateAlert: (orderId: string, token: string) => Promise<void>;
	fxRateAlert: FxRateAlert;
	orderId: string;
	token: string;
}

export const CancelRateAlertForm = (props: RateAlertCancelFormProps) => {
	const { cancelFxRateAlert, fxRateAlert, orderId, token } = props;
	const { t } = useTranslation();
	const [isPending, setIsPending] = useState(false);
	const [error, setError] = useState('');
	const [isCancelled, setIsCancelled] = useState(false);
	const { ccyPair, rate, createdAt } = fxRateAlert;

	const handleCancel = () => {
		setIsPending(true);
		cancelFxRateAlert(orderId, token)
			.then(() => setIsCancelled(true))
			.catch(e => setError(e.message))
			.finally(() => setIsPending(false));
	};

	return isCancelled ? (
		<>
			<Header>
				<h1 className="page-description__heading">
					{t('rateAlert.cancel.success.heading')}
				</h1>
				<p>{t('rateAlert.cancel.success.email')}</p>
			</Header>
			<p>
				* For general users of this website: please{' '}
				<Link to="/privacy-policy-general-users">click here</Link> to view the Privacy
				Policy in relation to the data collected and used by this website.
			</p>
		</>
	) : (
		<>
			<Header>
				<h1 className="page-description__heading">{t('rateAlert.cancel.heading')}</h1>
				<p>
					{t('rateAlert.cancel.alert', {
						postProcess: 'sprintf',
						sprintf: [ccyPair, rate, formatDate(createdAt)]
					})}
				</p>
			</Header>
			<Button
				aria-label={'click to cancel alert and accept terms and conditions'}
				data-testid="currency-rate-alert-cancel-button"
				size="small"
				disabled={isPending}
				onClick={handleCancel}
			>
				{t('rateAlert.cancel.cancelAlert')}
			</Button>
			{error && <p>{error}</p>}
		</>
	);
};
