import React, { useState } from 'react';
import { useTranslation } from 'utils/i18next';
import { FxTrendsCcyDirections, FxTrendsCcy } from 'types';
import { ResearchSection } from './ResearchSection';
import { ResearchHeading } from './ResearchHeading';
import { FxTrendsCurrencies } from './FxTrendsCurrencies';
import { FxTrendsOverview } from './FxTrendsOverview';
import { FxTrendsAccordions } from './FxTrendsAccordions';
import { FxTrendsDirections } from './FxTrendsDirections';
import { FxTrendsTitle } from './FxTrendsTitle';
import { FxTrendsPubDate } from './FxTrendsPubDate';
import { FxTrendsBrief } from './FxTrendsBrief';

const fxTrends: FxTrendsCcyDirections = {
	USD: {
		current: 'up',
		previous: 'up'
	},
	EUR: {
		current: 'sideways',
		previous: 'down'
	},
	GBP: {
		current: 'down',
		previous: 'sideways'
	},
	JPY: {
		current: 'up',
		previous: 'up'
	},
	CHF: {
		current: 'up',
		previous: 'up'
	},
	CAD: {
		current: 'up',
		previous: 'down'
	},
	AUD: {
		current: 'up',
		previous: 'down'
	},
	NZD: {
		current: 'sideways',
		previous: 'down'
	}
};

export const FxTrends = () => {
	const { t } = useTranslation('research');
	const [selectedCcy, setSelectedCcy] = useState<FxTrendsCcy>('USD');
	const { current, previous } = fxTrends[selectedCcy];
	const ccys = Object.keys(fxTrends) as FxTrendsCcy[];

	return (
		<ResearchSection flex={1}>
			<ResearchHeading>{t('fxTrends.heading')}</ResearchHeading>
			<FxTrendsPubDate>27 August 2024</FxTrendsPubDate>
			<FxTrendsTitle>{t('fxTrends.title')}</FxTrendsTitle>
			<FxTrendsBrief />
			<p>{t('fxTrends.selectCurrency')}</p>
			<FxTrendsCurrencies ccy={selectedCcy} ccys={ccys} onCcyChange={setSelectedCcy} />
			<FxTrendsDirections ccy={selectedCcy} current={current} previous={previous} />
			<FxTrendsOverview ccy={selectedCcy} />
			<FxTrendsAccordions />
		</ResearchSection>
	);
};
