import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { FxRate, FxRateAlertRegister } from 'types';
import { styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { invertCcyPair, mq, splitCcyPair } from 'utils/currency';
import { Button } from 'components/common/Button';
import { SwitchIcon } from 'components/icons';
import { CheckboxField, InputField } from 'components/form/formik';
import { RateAlertAccordions } from './RateAlertAccordions';
import { RateAlertDiff } from './RateAlertDiff';
import { RateAlertInputField, RateAlertValue } from './RateAlertInputField';
import { RateAlertCaptcha } from './RateAlertCaptcha';
import { ModalFooter } from '../Modal';

interface RateAlertFormProps extends RateAlertValue {
	fxRate: FxRate;
	ccyPair: string;
	submitBtnDisabled: boolean;
	onClose: () => void;
	onCcyPairChange: (ccyPair: string) => void;
	onSubmit: (rateAlert: FxRateAlertRegister) => void;
}

interface RateAlertFormValues {
	rate: string;
	email: string;
	marketing: boolean;
}

const RateAlertGrid = styled.div`
	display: grid;
	gap: 4px 8px;

	${mq({
		gridAutoFlow: 'row',
		gridTemplateColumns: [
			['auto auto'],
			['auto 2fr auto'],
			['auto 2fr auto'],
			['auto 2fr auto 4fr']
		],
		gridTemplateRows: [
			['auto auto 16px auto'],
			['auto 12px auto'],
			['auto 12px auto'],
			['auto 16px']
		]
	})}
`;

const FlipCcyButton = styled(Button)(
	mq({
		gridColumn: ['2/3', '3/4', '3/4', 'auto'],
		gridRow: ['2/3', '1/2', '1/2', 'auto']
	})
);

const StyledRateAlertDiff = styled(RateAlertDiff)`
	${mq({
		gridColumn: ['1/3', '1/4', '1/4', 'auto'],
		gridRow: ['4/5', '3/4', '3/4', 'auto']
	})}
`;

const StyledEmailInputField = styled(InputField)`
	${mq({
		maxWidth: ['100%', '100%', '100%', '415px']
	})}
`;

export const RateAlertForm = (props: RateAlertFormProps) => {
	const {
		fxRate,
		ccyPair,
		submitBtnDisabled,
		onClose,
		onCcyPairChange,
		onSubmit,
		rateAlertValue,
		onRateAlertValueChange
	} = props;
	const { t } = useTranslation();
	const [ccyFrom] = splitCcyPair(fxRate.ccyPair);

	const handleFormSubmit = (values: RateAlertFormValues) => {
		const rate = parseFloat(values.rate);
		const email = values.email;

		const side =
			fxRate.latest && fxRate.latest.open && fxRate.latest.open < rate ? 'SELL' : 'BUY';

		onSubmit({ ccyPair, email, rate, side });
	};

	const rateAlertInitialValues: RateAlertFormValues = {
		rate: '',
		email: '',
		marketing: false
	};

	const rateAlertFormSchema = Yup.object({
		rate: Yup.number().typeError(t('errors.invalidRate')).required(t('errors.required')),
		email: Yup.string().email(t('errors.invalidEmail')).required(t('errors.required')),
		marketing: Yup.boolean().oneOf([true, false], t('errors.required'))
	});

	return (
		<Formik
			initialValues={rateAlertInitialValues}
			validationSchema={rateAlertFormSchema}
			onSubmit={handleFormSubmit}
		>
			{({ isSubmitting, setFieldValue }) => (
				<Form>
					<RateAlertGrid data-testid="rate-alert-modal-form-grid">
						<RateAlertInputField
							id="rate-alert-value"
							data-testid="rate-alert-value"
							name="rate"
							ccyFrom={ccyFrom}
							rateAlertValue={rateAlertValue}
							onRateAlertValueChange={onRateAlertValueChange}
						/>
						<FlipCcyButton
							size="small"
							variant="tertiary"
							data-testid="currency-rate-form-invert-button"
							aria-label={t('aria.invertCcys')}
							onClick={() => {
								setFieldValue('rate', '', false);
								onCcyPairChange(invertCcyPair(ccyPair));
							}}
						>
							<SwitchIcon verticalAlign="middle" />
						</FlipCcyButton>
						<StyledRateAlertDiff fxRate={fxRate} rateAlert={rateAlertValue} />
					</RateAlertGrid>
					<RateAlertAccordions />
					<StyledEmailInputField
						id="rate-alert-email"
						data-testid="rate-alert-email"
						name="email"
						type="email"
						label={t('labels.email')}
					/>
					<CheckboxField
						id="rate-alert-checkbox"
						data-testid="rate-alert-checkbox"
						name="marketing"
						label={t('labels.alertCheckbox')}
					/>
					<p>{t('rateAlert.modal.terms')}</p>
					<RateAlertCaptcha />
					<ModalFooter>
						<Button
							data-testid="currency-rate-form-create-alert-button"
							size="small"
							type="submit"
							disabled={submitBtnDisabled || isSubmitting}
						>
							{t('labels.createAlert')}
						</Button>
						<Button
							data-testid="currency-rate-form-cancel-button"
							size="small"
							variant="tertiary"
							onClick={onClose}
						>
							{t('labels.cancel')}
						</Button>
					</ModalFooter>
				</Form>
			)}
		</Formik>
	);
};
