import React, { useEffect, useState } from 'react';
import { SendEvent } from 'utils/logging/types';
import { HeaderRegion, HeaderContext } from 'types';
import { useBreakpoint, useHeaderMenuItems, useHeaderTranslation } from 'hooks';
import { Flex, DropdownMenu } from 'components/common';
import { getStaticFileURL } from 'utils/url';
import { styled, typography } from 'utils/styles';
import { space } from 'styled-system';

interface NavGroupHighlightProps {
	region: HeaderRegion;
	context: HeaderContext;
	sendEvent: SendEvent;
}

const Heading = styled.h2(typography.s3, space);

const ContactUsParagraph = styled.p`
	a {
		color: white;
	}

	a::after {
		content: '';
		width: 11px;
		height: 11px;
		margin-left: 4px;
		margin-bottom: 4px;
		background-image: url('${getStaticFileURL('assets/icons/newWindow.svg')}');
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		display: inline-block;
	}
`;

export const NavGroupHighlight = (props: NavGroupHighlightProps) => {
	const { context, sendEvent, region } = props;
	const { bp } = useBreakpoint();
	const menuItems = useHeaderMenuItems(context);
	const t = useHeaderTranslation(region);
	const [view, setView] = useState<'mobile' | 'desktop'>('mobile');

	useEffect(() => {
		if (bp === 'mobile' || bp === 'tablet') {
			setView('mobile');
		} else {
			setView('desktop');
		}
	}, [bp]);

	return (
		<div>
			<Flex justifyContent="space-between" alignItems="center">
				<Heading mt="none">{t(`${context}.highlight.title`)}</Heading>
				{region === 'GB' && (
					<DropdownMenu
						display={['block', 'block', 'none']}
						menuItems={menuItems}
						sendEvent={sendEvent}
					/>
				)}
			</Flex>
			<p>{t(`${context}.highlight.paragraph1.${view}`)}</p>
			<p>{t(`${context}.highlight.paragraph2.${view}`)}</p>
			<Flex
				display={view === 'desktop' ? 'flex' : 'none'}
				alignItems="center"
				gridGap="medium"
			>
				<ContactUsParagraph>{t(`${context}.highlight.contactUs`)}</ContactUsParagraph>
			</Flex>
		</div>
	);
};
