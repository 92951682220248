import React, { useState } from 'react';
import { useTranslation } from 'utils/i18next';
import { FxTrendsCcy, tacticalView } from 'common/research';
import { FxTrendsCurrencies } from './FxTrendsCurrencies';
import { FxTrendsTacticalView } from './FxTrendsTacticalView';
import { FxTrendsAccordions } from './FxTrendsAccordions';
import { ResearchSection } from './ResearchSection';
import { ResearchHeading } from './ResearchHeading';

export const FxTrends = () => {
	const { t } = useTranslation();
	const [ccy, setCcy] = useState<FxTrendsCcy>('USD');
	const view = tacticalView[ccy];

	return (
		<ResearchSection flex={1}>
			<ResearchHeading>{t('researchNew.fxTrends.heading')}</ResearchHeading>
			<p>{t('researchNew.fxTrends.description')}</p>
			<p>{t('researchNew.fxTrends.selectCurrency')}</p>
			<FxTrendsCurrencies value={ccy} onChange={setCcy} />
			<FxTrendsTacticalView ccy={ccy} view={view} />
			<FxTrendsAccordions />
		</ResearchSection>
	);
};
