import React from 'react';
import { SVG, SVGProps } from './SVG';

export const ReadIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect data-name="Bounding box" width="18" height="18" />
			<path d="M1,0V18H17V0ZM15.8,16.8H2.2V1.2H15.8ZM8,8.4H4V9.6H8Zm0,5.4H4V15H8Zm0-2.7H4v1.2H8ZM10,15h4V8.4H10Zm1.2-5.4h1.6v4.2H11.2ZM14,3H4V4.2H14Zm0,2.7H4V6.9H14Z" />
		</g>
	</SVG>
);
