import React from 'react';
import { EVENTS, SendEvent, TealiumEvent } from 'utils/logging/types';
import { typography } from 'utils';
import { getDateOfLastDay } from 'utils/date';
import { Article, Day } from 'types';
import { ExternalLink, Flex } from './common';
import { ArticleFrequencyBadge, ArticleTypeBadge } from './ResearchArticleBadge';
import { mq, styled } from 'utils/styles';

interface ResearchArticleProps {
	article: Article;
	sendEvent: SendEvent;
}

const ArticleLink = styled(ExternalLink)`
	display: flex;
	padding: 8px;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}

	${mq({
		width: ['100%', '50%', '33.3%']
	})}
`;

const ArticleContainer = styled.article`
	flex: 1;
	display: flex;
	flex-direction: column;
	color: ${({ theme }) => theme.colors.typography};
	background-color: ${({ theme }) => theme.colors.white};
	box-shadow: 0 1px 2px ${({ theme }) => theme.colors.grey4};

	&:hover {
		box-shadow: 0 1px 5px 1px ${({ theme }) => theme.colors.grey4};
		transform: translate3d(0, -1px, 0);
	}
`;

const ArticleImage = styled('div', { shouldForwardProp: prop => prop !== 'src' })<{ src: string }>`
	background-image: url('${({ src }) => src}');
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	aspect-ratio: 16 / 9;
`;

const ArticleContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: justify;
	background-color: ${({ theme }) => theme.colors.white};
	padding: 1rem 1.5rem 1.5rem;
`;

const Title = styled.h3`
	${typography.s5}
	font-weight: ${({ theme }) => theme.fontWeight.bold};
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey4};
	padding-bottom: 0.875rem;
	margin: 0 0 0.875rem;
`;

const Time = styled.time(typography.s7);

export const ResearchArticle = (props: ResearchArticleProps) => {
	const { article } = props;

	return (
		<ArticleLink
			href={article.link.href}
			trackingEvent={EVENTS.PREDEFINED_EVENTS[article.trackingEvent] as TealiumEvent}
			withPopup={article.link.external}
			data-testid={article.title}
		>
			<ArticleContainer>
				<ArticleImage src={article.image} />
				<ArticleContent>
					<div>
						<Title>{article.title}</Title>
						{article.frequency && (
							<Flex gridGap="large" paddingTop={'0.30rem'}>
								<Time>
									{article.date === 'every_thursday'
										? new Intl.DateTimeFormat('en-GB', {
												year: 'numeric',
												month: 'long',
												day: 'numeric'
											}).format(getDateOfLastDay(Day.THURSDAY))
										: article.date}
								</Time>
								<ArticleFrequencyBadge frequency={article.frequency} />
							</Flex>
						)}
						<p>{article.text}</p>
					</div>
					<Flex justifyContent="space-between">
						<ArticleTypeBadge type={article.type} />
					</Flex>
				</ArticleContent>
			</ArticleContainer>
		</ArticleLink>
	);
};
