import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { getEnv } from 'utils/url';
import { useSSR } from 'utils/i18next';
import { Global, globalStyle, styled } from 'utils/styles';
import { FxRate, QueryParams } from 'types';
import config from 'config';
import Home from './pages/Home';
import { Calculator } from './pages/Calculator';
import TermsAndConditions from './pages/TermsAndConditions';
import Privacy from './pages/Privacy';
import PrivacyGeneralUsers from './pages/PrivacyGeneralUsers';
import NotFound from './pages/NotFound';
import CookieNotice from './pages/CookieNotice';
import BrowserUnsupported from './pages/BrowserUnsupported';
import { ConfirmEmail } from './pages/RateAlerts';
import { CancelRateAlert } from './pages/CancelRateAlert';
import { CancelAllRateAlerts } from './pages/CancelAllRateAlerts';
import { ContactUs } from './pages/ContactUs';
import { Header } from './Header';
import { Footer } from './Footer';

interface AppProps {
	rate?: FxRate;
	query?: QueryParams;
}

const Main = styled.main`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.white};
`;

const routes = [
	'/calculator',
	'/terms-conditions',
	'/cookie-notice',
	'/privacy-policy',
	'/privacy-policy-general-users',
	'/contact-us',
	'/unsupported'
];

const App = (props: AppProps) => {
	const { rate = globalThis.initial_state?.rate, query = global.initial_state?.query } = props;
	useSSR(globalThis.initial_state?.i18n.store.data, globalThis.initial_state?.i18n.language);
	const { search } = useLocation();

	return (
		<>
			<Helmet {...config.head} />
			<Global styles={globalStyle} />
			<Header />
			<Main>
				<Switch>
					<Route key="home" exact path="/">
						<Home rate={rate} query={query} />
					</Route>
					<Route key="calculator" exact strict path="/calculator/">
						<Calculator rate={rate} query={query} />
					</Route>
					<Route key="terms" exact strict path="/terms-conditions/">
						<TermsAndConditions />
					</Route>
					<Route key="cookie" exact strict path="/cookie-notice/">
						<CookieNotice />
					</Route>
					<Route key="privacy" exact strict path="/privacy-policy/">
						<Privacy />
					</Route>
					<Route key="privacy2" exact strict path="/privacy-policy-general-users/">
						<PrivacyGeneralUsers />
					</Route>
					<Route key="alerts-confirm" exact strict path="/alerts/confirm/:token">
						{getEnv() === 'prd' && <Redirect to="/" />}
						<ConfirmEmail />
					</Route>
					<Route key="alerts-cancel" exact strict path="/alerts/cancel/:orderId">
						{getEnv() === 'prd' && <Redirect to="/" />}
						<CancelRateAlert />
					</Route>
					<Route key="alerts-cancelall" exact strict path="/alerts/cancelall">
						{getEnv() === 'prd' && <Redirect to="/" />}
						<CancelAllRateAlerts />
					</Route>
					<Route key="contact-us" exact strict path="/contact-us/">
						{getEnv() === 'prd' && <Redirect to="/" />}
						<ContactUs />
					</Route>
					<Route key="unsupported" exact strict path="/unsupported/">
						<BrowserUnsupported />
					</Route>
					{routes.map(route => (
						<Redirect
							exact
							strict
							key={route}
							from={route}
							to={{
								pathname: route + '/',
								search
							}}
						/>
					))}
					<Route key="404" exact path="*">
						<NotFound />
					</Route>
				</Switch>
			</Main>
			<Footer />
		</>
	);
};

export default App;
