import React, { useRef } from 'react';
import { useTranslation } from 'utils/i18next';
import { styled } from 'utils/styles';
import { getStaticFileURL } from 'utils/url';
import { ResearchHeading } from './ResearchHeading';
import { ResearchSection } from './ResearchSection';

const StyledVideoJs = styled.div`
	display: flex;
	justify-content: flex-start;

	iframe {
		border: none;
		width: 100%;
		aspect-ratio: 16/9;
		object-fit: contain;
	}
`;

export const FxVideo = () => {
	const { t } = useTranslation('research');
	const playerRef = useRef<HTMLDivElement>(null);

	const path = getStaticFileURL('assets/video-playlist/index.html');
	return (
		<ResearchSection>
			<ResearchHeading>{t('video.heading')}</ResearchHeading>
			<StyledVideoJs ref={playerRef}>
				<iframe title="No report avaiable" src={`${path}?playlistId=1805634502603502368`} />
			</StyledVideoJs>
		</ResearchSection>
	);
};
