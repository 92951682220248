import React from 'react';
import { FxTrend, FxTrendsCcy } from 'types';
import { css, styled } from 'utils/styles';
import { useTranslation } from 'utils/i18next';
import { Flex } from 'components/common';
import { PlayIcon } from 'components/icons';

interface FxTrendsDirectionsProps {
	ccy: FxTrendsCcy;
	current: FxTrend;
	previous: FxTrend;
}

type DirectionsColors = {
	[key in FxTrend]: string;
};

const DirectionsContainer = styled.div(
	({ theme: { space, colors } }) => css`
		display: flex;
		align-items: center;
		gap: ${space.large};
		padding: ${space.medium};
		margin-top: ${space.medium};
		background: ${colors.grey1};
	`
);

const DirectionsCurrency = styled.div`
	font-size: 2.036rem;
`;

const DirectionsHeading = styled.div`
	span {
		font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
	}
`;

const ShortTermTrendIcon = styled(PlayIcon)<{ trend: FxTrend }>(({ theme: { colors }, trend }) => {
	const directionsColors: DirectionsColors = {
		up: colors.rag.green,
		down: colors.rag.red,
		sideways: colors.grey4
	};

	return css`
		fill: ${directionsColors[trend]};
		transform: ${trend === 'sideways'
			? 'rotate(90deg)'
			: trend === 'up'
				? 'rotate(0deg)'
				: 'rotate(180deg)'};
	`;
});

export const FxTrendsDirections = (props: FxTrendsDirectionsProps) => {
	const { current, previous, ccy } = props;
	const { t } = useTranslation('research');

	return (
		<DirectionsContainer>
			<DirectionsCurrency>{ccy}</DirectionsCurrency>
			<Flex flexDirection="column" gridGap="10px">
				<DirectionsHeading>
					{t('fxTrends.shortTermDirection')} <span>DXY^</span>
				</DirectionsHeading>
				<Flex alignItems="center" justifyContent="space-between" gridGap="6px">
					<Flex gridGap="6px" alignItems="center">
						{t('fxTrends.current')}:
						<ShortTermTrendIcon size="medium" trend={current} />
					</Flex>
					<Flex gridGap="6px" alignItems="center">
						{t('fxTrends.previous')}:
						<ShortTermTrendIcon size="medium" trend={previous} />
					</Flex>
				</Flex>
			</Flex>
		</DirectionsContainer>
	);
};
