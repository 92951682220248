import React from 'react';
import { Article } from 'types';
import { event } from 'utils/logger';
import { SendEvent } from 'utils/logging/types';
import { useTranslation } from 'utils/i18next';
import { getStaticFileURL } from 'utils/url';
import { Flex, Section } from 'components/common';
import { ResearchArticle } from './ResearchArticle';

interface ResearchArticlesProps {
	sendEvent?: SendEvent;
}

export const ResearchArticles = (props: ResearchArticlesProps) => {
	const { sendEvent = event } = props;
	const { t } = useTranslation();

	const articles: Article[] = [
		{
			image: getStaticFileURL('assets/articles/fxtrends.jpg'),
			title: t('research.fxTrends.title'),
			text: t('research.fxTrends.text'),
			link: {
				href: getStaticFileURL('assets/FXTrendsJuly2024.pdf'),
				external: false
			},
			type: 'article',
			date: '22 July 2024',
			frequency: 'monthly',
			trackingEvent: 'READ_FX_TRENDS'
		},
		{
			image: getStaticFileURL('assets/articles/podcast.jpg'),
			title: t('research.podcast.title'),
			text: t('research.podcast.text'),
			link: {
				href: 'https://www.gbm.hsbc.com/en-gb/feed/global-research/podcasts/macro-viewpoint',
				external: true
			},
			type: 'podcast',
			date: 'every_thursday',
			frequency: 'weekly',
			trackingEvent: 'READ_MACROVIEW_PODCAST'
		},
		{
			image: getStaticFileURL('assets/articles/insights.jpg'),
			title: t('research.insights.title'),
			text: t('research.insights.text'),
			link: {
				href: 'https://www.gbm.hsbc.com/en-gb/campaigns/global-research/live-insights',
				external: true
			},
			type: 'video',
			trackingEvent: 'READ_GR_LIVE_INSIGHTS'
		}
	];

	return (
		<Section id="research-articles" data-testid="research-articles" data-nosnippet>
			<h2>{t('research.title')}</h2>
			<p>{t('research.heading')}</p>
			<Flex flexWrap="wrap" id="articles">
				{articles.map((article, idx) => (
					<ResearchArticle key={idx} article={article} sendEvent={sendEvent} />
				))}
			</Flex>
		</Section>
	);
};
