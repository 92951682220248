import { http } from 'utils/http';
import { isLocalhost } from 'utils/dom/window';
import { Range, FxRate, FxRateAlert, FxRateAlertRegister } from 'types';
import { AxiosRequestConfig } from 'axios';

const prefix = isLocalhost() ? 'http://localhost:8085/currency-zone/v1' : '/currency-zone/v1';

export const fetchFxRates = async (
	ccyPairs: string[],
	range: Range,
	config?: AxiosRequestConfig
): Promise<FxRate[]> => {
	const url = `${prefix}/ccypairsrates?ccyPairs=${ccyPairs.join(',')}&range=${range}`;
	const { data } = await http.get<FxRate[]>(url, config);
	return data.map(item => ({ ...item, range }));
};

export const fetchFxRate = async (
	ccyFrom: string,
	ccyTo: string,
	range: Range,
	config?: AxiosRequestConfig
): Promise<FxRate> => {
	const [fxRate] = await fetchFxRates([ccyFrom + ccyTo], range, config);
	return fxRate;
};

export const fetchFxRateAlert = async (
	orderId: string,
	token: string,
	config?: AxiosRequestConfig
): Promise<FxRateAlert> => {
	const url = `${prefix}/alerts/${orderId}?token=${token}`;
	const { data } = await http.get<FxRateAlert>(url, config);
	return data;
};

export const registerFxRateAlert = async (
	payload: FxRateAlertRegister,
	config?: AxiosRequestConfig
): Promise<FxRateAlertRegister> => {
	const url = `${prefix}/alerts`;
	const { data } = await http.post<FxRateAlertRegister>(url, payload, config);
	return data;
};

export const confirmFxRateAlertEmail = async (
	token: string,
	config?: AxiosRequestConfig
): Promise<void> => {
	const url = `${prefix}/alerts/confirm/${token}`;
	await http.get(url, config);
};

export const cancelFxRateAlert = async (
	orderId: string,
	token: string,
	config?: AxiosRequestConfig
): Promise<void> => {
	const url = `${prefix}/alerts/cancel/${orderId}?token=${token}`;
	await http.get(url, config);
};

export const cancelAllFxRateAlerts = async (
	token?: string,
	config?: AxiosRequestConfig
): Promise<void> => {
	const url = `${prefix}/alerts/cancelall?token=${token}`;
	await http.post(url, config);
};
