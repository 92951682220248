const config = {
	head: {
		defaultTitle: 'HSBC Currency Zone',
		titleTemplate: '%s - HSBC Currency Zone',
		meta: [
			{
				name: 'description',
				content:
					'Check, track and chart foreign exchange (FX) rates. Stay on top of the market with insights and HSBC Global Research.'
			},
			{ name: 'robots', content: 'noindex, nofollow' }
		]
	},
	eloqua: {
		url: 'https://s268905678.t.eloqua.com/e/f2',
		formName: 'FXTestFormSB4',
		siteID: '268905678'
	}
};

export default config;
