import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { parseQueryParams } from 'utils/url';
import { useTranslation } from 'utils/i18next';
import { Button, Header, Page } from 'components/common';
import { cancelAllFxRateAlerts as cancelAllFxRateAlertsDefault } from 'services/currency-zone-service';

interface CancelAllRateAlertsProps {
	cancelAllFxRateAlerts?: (token?: string) => Promise<void>;
}

export const CancelAllRateAlerts = (props: CancelAllRateAlertsProps) => {
	const { cancelAllFxRateAlerts = cancelAllFxRateAlertsDefault } = props;
	const [isPending, setIsPending] = useState(false);
	const [error, setError] = useState(false);
	const [isCancelled, setIsCancelled] = useState(false);
	const location = useLocation();
	const { token } = parseQueryParams(location.search);
	const { t } = useTranslation();

	const handleCancel = () => {
		setIsPending(true);
		cancelAllFxRateAlerts(token)
			.then(() => setIsCancelled(true))
			.catch(() => setError(true))
			.finally(() => setIsPending(false));
	};

	return (
		<Page>
			<Helmet title="Cancel rate alert" />
			{isCancelled ? (
				<>
					<Header>
						<h1>{t('rateAlert.cancelAll.success.heading')}</h1>
					</Header>
					<p>
						* For general users of this website: please{' '}
						<Link to="/privacy-policy-general-users">click here</Link> to view the
						Privacy Policy in relation to the data collected and used by this website.
					</p>
				</>
			) : (
				<>
					<Header>
						<h1>{t('rateAlert.cancelAll.heading')}</h1>
					</Header>
					<Button
						aria-label={'click to cancel alert and accept terms and conditions'}
						size="small"
						disabled={isPending || !token}
						onClick={handleCancel}
						marginBottom="large"
					>
						{t('rateAlert.cancelAll.cancelAlert')}
					</Button>
					{error && (
						<>
							<p>{t('rateAlert.cancelAll.error.heading')}</p>
							<p>{t('rateAlert.cancelAll.error.additionalText')}</p>
						</>
					)}
					<p>
						* For general users of this website: please{' '}
						<Link to="/privacy-policy-general-users">click here</Link> to view the
						Privacy Policy in relation to the data collected and used by this website.
					</p>
				</>
			)}
		</Page>
	);
};
