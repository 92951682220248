import React, { ReactNode } from 'react';
import { getEnv } from 'utils/url';
import { ClientOnly } from './ClientOnly';

interface UATOnlyProps {
	children: ReactNode;
}

export const UATOnly = ({ children }: UATOnlyProps) => {
	const env = getEnv();

	return <ClientOnly>{env !== 'prd' ? children : null}</ClientOnly>;
};
