import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { FormState, FxRate, FxRateMap, QueryParams, Range } from 'types';
import { scrollTo } from 'utils';
import { styled, visuallyHidden } from 'utils/styles';
import { event } from 'utils/logger';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { HttpRequestConfig } from 'utils/http';
import { useTranslation } from 'utils/i18next';
import { keyBy } from 'lodash';
import { ClientOnly } from 'components/common';
import watchlistService, { WatchlistService } from 'services/watchlist-service';
import {
	fetchFxRate as fetchFxRateDefault,
	fetchFxRates as fetchFxRatesDefault
} from 'services/currency-zone-service';
import { CurrencyRateConverter } from './CurrencyRateConverter';
import { LiveExchangeRates } from './LiveExchangeRates';
import { invertCcyPair, splitCcyPair } from 'utils/currency';
import { parseQueryParams, stringifyQueryParams } from 'utils/url';

export const REFRESH_RATES_INTERVAL = 60000;
export const REFRESH_RATES_ON_ERROR_INTERVAL = 10000;

const HiddenSpan = styled.span(visuallyHidden);

interface CurrencyZoneProps {
	sendEvent?: SendEvent;
	watchlist?: WatchlistService;
	fetchFxRates?: (ccyPairs: string[], range: Range) => Promise<FxRate[]>;
	fetchFxRate?: (ccyFrom: string, ccyTo: string, range: Range) => Promise<FxRate>;
	rate?: FxRate;
	query?: QueryParams;
	withWatchlist?: boolean;
}

export const CurrencyZone = (props: CurrencyZoneProps) => {
	const {
		sendEvent = event,
		watchlist = watchlistService,
		fetchFxRates = fetchFxRatesDefault,
		fetchFxRate = fetchFxRateDefault,
		rate: initialRate,
		query: initialQuery,
		withWatchlist = true
	} = props;
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();

	const [formState, setFormState] = useState<FormState>({
		amount: initialQuery?.amount || 1,
		ccyFrom: initialQuery?.ccyFrom || null,
		ccyTo: initialQuery?.ccyTo || null
	});
	const [rateRange, setRateRange] = useState<Range | null>(initialQuery?.range || null);
	const [fxRate, setFxRate] = useState<FxRate | null>(initialRate || null);
	const [isPending, setPendingStatus] = useState<boolean>(false);
	const [ccyPairs, setCcyPairs] = useState<string[]>(watchlist.get());
	const [watchlistPendingState, setWatchlistPendingState] = useState<string>('all');
	const [fxRates, setFxRates] = useState<FxRateMap>({});
	const [flash, setFlash] = useState<boolean>(false);
	const [isTimerEnabled, setTimer] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const { i18n } = useTranslation();

	useEffect(() => {
		if (initialQuery) {
			const { ccyFrom, ccyTo, range } = initialQuery;
			if (!ccyFrom || !ccyTo || !range) {
				setError(t('errors.invalidParams'));
			}
		}
	}, [initialQuery, t]);

	useEffect(() => {
		const existingQueries = parseQueryParams(location.search);
		if (formState.ccyFrom) existingQueries.ccyFrom = formState.ccyFrom;
		if (formState.ccyTo) existingQueries.ccyTo = formState.ccyTo;
		if (rateRange) existingQueries.range = rateRange;
		const queryString = stringifyQueryParams({
			...existingQueries,
			amount: formState.amount,
			lng: i18n.language
		});
		history.push(`${location.pathname}?${queryString}`, { replace: true });
	}, [
		formState.amount,
		formState.ccyFrom,
		formState.ccyTo,
		location.pathname,
		location.search,
		rateRange,
		history,
		i18n.language
	]);

	const runFlashAnimation = useCallback(() => {
		setFlash(true);
		setTimeout(() => setFlash(false), 5000);
	}, []);

	const fetchData = useCallback(async () => {
		try {
			if (formState.ccyFrom && formState.ccyTo && rateRange) {
				setError('');
				setWatchlistPendingState('all');
				setPendingStatus(true);

				const [rate, rates] = await Promise.all([
					fetchFxRate(formState.ccyFrom, formState.ccyTo, rateRange),
					ccyPairs.length ? fetchFxRates(ccyPairs, Range.DAY) : Promise.resolve([])
				]);

				setFxRate(rate);
				if (ccyPairs.length) {
					setFxRates(keyBy(rates, 'ccyPair'));
				}
			}
		} catch (e) {
			setError(t('errors.generic'));
			setFxRate(null);
		} finally {
			setWatchlistPendingState('');
			setPendingStatus(false);
		}
	}, [ccyPairs, fetchFxRate, fetchFxRates, formState.ccyFrom, formState.ccyTo, rateRange, t]);

	useEffect(() => {
		// todo: solve node/dom timer types independence
		let timeout: NodeJS.Timeout;
		const abortCtrl = new AbortController();
		const config: HttpRequestConfig = { signal: abortCtrl.signal };

		if (rateRange === Range.TWO_YEARS) {
			config.timeout = 30000;
		}

		const fetchRates = async (withFlash = true) => {
			try {
				if (formState.ccyFrom && formState.ccyTo && rateRange) {
					setError('');
					setTimer(false);

					const [rate, rates] = await Promise.all([
						fetchFxRate(formState.ccyFrom, formState.ccyTo, rateRange, config),
						ccyPairs.length
							? fetchFxRates(ccyPairs, Range.DAY, config)
							: Promise.resolve([])
					]);

					setFxRate(rate);
					if (ccyPairs.length) {
						setFxRates(keyBy(rates, 'ccyPair'));
					}

					setTimer(true);
					timeout = setTimeout(fetchRates, REFRESH_RATES_INTERVAL);
					if (withFlash) {
						runFlashAnimation();
					}
				}
			} catch (e: unknown) {
				if (e instanceof AxiosError) {
					if (e.response?.status === 400) {
						setError(t('errors.invalidParams'));
						setTimer(false);
						clearTimeout(timeout);
					} else if (e.code !== 'ERR_CANCELED') {
						setError(t('errors.generic'));
						setFxRate(null);
						setTimer(true);
						timeout = setTimeout(fetchRates, REFRESH_RATES_ON_ERROR_INTERVAL);
					}
				}
			} finally {
				setWatchlistPendingState('');
				setPendingStatus(false);
			}
		};

		(async () => {
			if (globalThis.initial_state?.rate) {
				setTimer(true);
				timeout = setTimeout(fetchRates, REFRESH_RATES_INTERVAL);
				global.initial_state.rate = undefined;

				if (ccyPairs.length) {
					fetchFxRates(ccyPairs, Range.DAY, config).then(rates => {
						setFxRates(keyBy(rates, 'ccyPair'));
						setWatchlistPendingState('');
					});
				}
			} else {
				setPendingStatus(true);
				await fetchRates(false);
			}
		})();

		return () => {
			abortCtrl.abort();
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [
		ccyPairs,
		fetchFxRate,
		fetchFxRates,
		formState.ccyFrom,
		formState.ccyTo,
		rateRange,
		runFlashAnimation,
		t
	]);

	const handleSetFormState = useCallback((formState: Partial<FormState>) => {
		setFormState(prevState => ({ ...prevState, ...formState }));
	}, []);

	const handleSetRateRange = useCallback(
		(range: Range) => {
			setRateRange(currentRange => {
				if (currentRange !== range) {
					sendEvent(EVENTS.PREDEFINED_EVENTS.CHANGE_TIME_RANGE(range));
				}
				return range;
			});
		},
		[sendEvent]
	);

	const handleRateClick = (ccyPair: string) => {
		const [ccyFrom, ccyTo] = splitCcyPair(ccyPair);
		setFormState(state => ({ ...state, ccyFrom, ccyTo }));
		setRateRange(Range.DAY);
		scrollTo('currency-rate-converter', 100);
		sendEvent(EVENTS.PREDEFINED_EVENTS.SELECT_FAV_CCY(ccyPair));
	};

	const handleRateAdd = useCallback(
		(ccyPair: string) => {
			if (!ccyPairs.includes(ccyPair)) {
				setCcyPairs(ccyPairs => [...ccyPairs, ccyPair]);
				setWatchlistPendingState(ccyPair);
				watchlist.add(ccyPair);
			}
			sendEvent(EVENTS.PREDEFINED_EVENTS.ADD_TO_FAV(ccyPair));
		},
		[ccyPairs, watchlist, sendEvent]
	);

	const handleRateRemove = (ccyPair: string) => {
		setCcyPairs(ccyPairs => ccyPairs.filter(_ccyPair => _ccyPair !== ccyPair));
		watchlist.remove(ccyPair);
		sendEvent(EVENTS.PREDEFINED_EVENTS.REMOVE_FAV_CCY(ccyPair));
	};

	const handleRateInvert = (ccyPair: string) => {
		const invertedCcyPair = invertCcyPair(ccyPair);
		setCcyPairs(ccyPairs =>
			ccyPairs.map(_ccyPair => (_ccyPair !== ccyPair ? _ccyPair : invertedCcyPair))
		);
		setWatchlistPendingState(invertedCcyPair);
		watchlist.invert(ccyPair);
		sendEvent(EVENTS.PREDEFINED_EVENTS.SWITCH_QUOTE_FAV(ccyPair));
	};

	return (
		<>
			<CurrencyRateConverter
				fxRate={fxRate}
				rateRange={rateRange}
				formState={formState}
				isPending={isPending}
				error={error}
				flash={flash}
				onFormStateChange={handleSetFormState}
				onRateRangeChange={handleSetRateRange}
				onErrorCancel={() => setError('')}
				onDataRefresh={fetchData}
				sendEvent={sendEvent}
			/>
			{withWatchlist && (
				<ClientOnly>
					<LiveExchangeRates
						ccyPairs={ccyPairs}
						fxRates={fxRates}
						flash={flash}
						isTimerEnabled={isTimerEnabled}
						loadingState={watchlistPendingState}
						error={error}
						onErrorCancel={() => setError('')}
						onRateAdd={handleRateAdd}
						onRateClick={handleRateClick}
						onRateInvert={handleRateInvert}
						onRateRemove={handleRateRemove}
						onDataRefresh={fetchData}
						refreshRatesInterval={REFRESH_RATES_INTERVAL}
						maxListSize={20}
					/>
				</ClientOnly>
			)}
			{flash && <HiddenSpan role="status">{t('aria.ratesRefreshed')}</HiddenSpan>}
		</>
	);
};
