import translation from './translation.json';
import currencies from './currencies.json';
import headerGB from './headerGB.json';
import headerHK from './headerHK.json';

const englishLocale = {
	translation,
	currencies,
	headerGB,
	headerHK
};

export default englishLocale;
