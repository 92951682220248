import { useEffect, useState } from 'react';
import { getUserLocation } from 'utils/url';
import { UserLocation } from 'types';

export const useUserLocation = (): UserLocation => {
	const [userLocation, setUserLocation] = useState<UserLocation>('OTHER');

	useEffect(() => {
		// [workaround] wait for utag script - todo: find better solution
		const t = setTimeout(() => setUserLocation(getUserLocation()), 1000);
		return () => clearTimeout(t);
	}, []);

	return userLocation;
};
