import React from 'react';
import { SVG, SVGProps } from './SVG';

export const RenewIcon = (props: SVGProps) => (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
		<g>
			<rect id="Bounding_box" data-name="Bounding box" width="18" height="18" />
			<path d="M9,16.8a7.79,7.79,0,0,1-7.1-4.6H7L5.8,11H0v5.8L1.2,18V13.46A9,9,0,0,0,18,9H16.8A7.81,7.81,0,0,1,9,16.8ZM16.8,0V4.54A9,9,0,0,0,0,9H1.2A7.79,7.79,0,0,1,16.1,5.8H11L12.2,7H18V1.2Z" />
		</g>
	</SVG>
);
