import React, { useEffect } from 'react';
import { css, styled } from 'utils/styles';
import { HeaderContext, HeaderRegion } from 'types';
import { useTranslation } from 'utils/i18next';
import { EVENTS, SendEvent } from 'utils/logging/types';
import { useBreakpoint, useHeaderMenuItems } from 'hooks';
import { DropdownMenu, ExternalLink } from 'components/common';

interface NavBarProps {
	sendEvent: SendEvent;
	context: HeaderContext | null;
	onContextChange: (context: HeaderContext) => void;
	region: HeaderRegion;
	tabIndex: number;
}

const NavList = styled.ul`
	display: inline-block;
	margin: 0;
	padding: 0;
`;

const NavListItem = styled.li`
	display: inline-block;
`;

const MenuItem = styled(ExternalLink)<{ selected: boolean }>(
	({ selected, theme: { colors, fontSize } }) => css`
		cursor: pointer;
		border: none;
		background-color: transparent;
		text-decoration: none;
		color: ${colors.typography};
		font-size: ${fontSize.medium};
		padding: 26px 4px;
		margin: 0 5px;
		position: relative;

		&::after {
			background-color: ${colors.redHSBC};
			content: '';
			height: 5px;
			top: auto;
			left: 0;
			bottom: 0;
			position: absolute;
			transition: width 0.15s cubic-bezier(0.47, 0, 0.745, 0.715);
			width: ${selected ? '100%' : 0};
		}

		&:hover {
			text-decoration: none;
			&::after {
				width: 100%;
			}
		}
	`
);

export const NavBar = (props: NavBarProps) => {
	const { sendEvent, context, onContextChange, region, tabIndex } = props;
	const { bp } = useBreakpoint();
	const { t } = useTranslation();
	const menuItems = useHeaderMenuItems(context);

	useEffect(() => {
		const root = document.querySelector('#root') as HTMLElement;

		// disable app scroll, todo: find better way to do that
		if (root) {
			if (bp === 'mobile' && context) {
				root.style.position = 'fixed';
				root.style.overflow = 'hidden';
				root.style.left = '0';
				root.style.right = '0';
			} else {
				root.style.position = 'initial';
				root.style.overflow = 'initial';
				root.style.left = 'initial';
				root.style.right = 'initial';
			}
		}
	}, [bp, context]);

	return (
		<>
			<nav>
				<NavList>
					<NavListItem>
						<MenuItem
							selected={context === 'personal'}
							tabIndex={tabIndex}
							withPopup={region !== 'GB'}
							href={
								region === 'GB'
									? undefined
									: 'https://internationalservices.hsbc.com/global-directory'
							}
							onClick={
								region === 'GB' ? () => onContextChange('personal') : undefined
							}
						>
							{t('header.personal')}
						</MenuItem>
					</NavListItem>
					<NavListItem>
						<MenuItem
							selected={context === 'business'}
							tabIndex={tabIndex}
							withPopup={region === 'OTHER'}
							href={
								region === 'OTHER'
									? 'https://www.business.hsbc.com/international-markets'
									: undefined
							}
							onClick={
								region === 'OTHER' ? undefined : () => onContextChange('business')
							}
							trackingEvent={
								region === 'OTHER'
									? EVENTS.PREDEFINED_EVENTS.CLICK_ON_BUSINESS_REFFERAL
									: undefined
							}
						>
							{t('header.business')}
						</MenuItem>
					</NavListItem>
				</NavList>
			</nav>
			{region === 'GB' && (
				<DropdownMenu
					display={['none', 'none', 'block']}
					menuItems={menuItems}
					sendEvent={sendEvent}
				/>
			)}
		</>
	);
};
