import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
import { hsbcTheme, ThemeProvider } from './app/utils';
import { getBaseUrl } from './app/utils/dom/window';
import { log } from './app/utils/logger';
import App from './app/components/App';
import createEmotionCache from './app/utils/createEmotionCache';

import './app/utils/i18next';

const cache = createEmotionCache();
const baseUrl = getBaseUrl();

hydrateRoot(
	globalThis.document.getElementById('root'),
	<BrowserRouter basename={baseUrl}>
		<CacheProvider value={cache}>
			<ThemeProvider theme={hsbcTheme}>
				<HelmetProvider>
					<App />
				</HelmetProvider>
			</ThemeProvider>
		</CacheProvider>
	</BrowserRouter>
);

log('App has been hydrated');
