import React, { ReactNode } from 'react';
import { getEnv } from 'utils/url';
import { ClientOnly } from './ClientOnly';

interface PRDOnlyProps {
	children: ReactNode;
}

export const PRDOnly = ({ children }: PRDOnlyProps) => {
	const env = getEnv();

	return <ClientOnly>{env === 'prd' ? children : null}</ClientOnly>;
};
