export type FxTrend = 'up' | 'down' | 'sideways';
export type FxTrendsCcy = 'USD' | 'EUR' | 'GBP' | 'JPY' | 'CHF' | 'CAD' | 'AUD' | 'NZD';

export type TacticalView = {
	text: string;
	current: FxTrend;
	prev: FxTrend;
};

type TacticalViewMap = {
	[ccy in FxTrendsCcy]: TacticalView;
};

export const fxTrendsCcy: FxTrendsCcy[] = ['USD', 'EUR', 'GBP', 'JPY', 'CHF', 'CAD', 'AUD', 'NZD'];
export const tacticalView: TacticalViewMap = {
	USD: {
		text: 'In October, markets will probably debate whether or not the Federal Reserve (Fed) will have an additional rate hike this year. The current pricing points to a 1-in-4 chance of a hike in the Federal Open Market Committee (FOMC) meeting on 31 October -1 November and a 50:50 call for that in December (Bloomberg, 27 September 2023), despite the fact that 12 FOMC members see higher rates this year. This creates an asymmetric upside for the USD, in our view. The September US CPI release on 12 October is the key highlight, which our economists expect the headline inflation to hold at 3.7% and the core inflation to slow modestly to 4.2%. However, the determination of Organization of the Petroleum Exporting Countries (OPEC) and its allies (known as OPEC+) to support oil prices is hardly encouraging for inflation. The September US employment report on 6 October, and the first estimate of Q3 US GDP on 26 October may add some colour to the market debate. Finally, the USD may have to navigate temporary headwinds, such as a possible government shutdown and auto sector industrial action. All in all, we look for the US Dollar Index (DXY) to extend its gain over the coming month.',
		current: 'up',
		prev: 'down'
	},
	EUR: {
		text: 'Relative rate differentials and risk aversion could continue to pressure EUR-USD lower over the next few weeks, in our view. For the meeting on 26 October, even if the European Central Bank (ECB) becomes more hawkish, it is not clear the EUR would benefit, given the challenging growth-inflation mix. That being said, given that there has been a sizeable adjustment in rates already, EUR downside should be more limited. Parity appears to remain a distant prospect.',
		current: 'down',
		prev: 'up'
	},
	GBP: {
		text: 'The GBP has been the worst performing G10 currency so far in September, but the drivers remain in play for a grind lower over the near term, in our view. The Bank of England (BoE) vote to keep rates steady in September was won in a narrow margin. The policy outlook may be finely balanced on whether a rate hike is needed on 2 November, while our economists believe the BoE tightening cycle has finished. The UK economy still faces a challenging outlook, amid slowing credit growth and more fixed-rate mortgages expiring.',
		current: 'down',
		prev: 'sideways'
	},
	JPY: {
		text: 'The Bank of Japan (BoJ) monetary policy is likely to remain an irrelevance for the JPY beyond the market’s flirtation with policy change ahead of each meeting, with the next one on 31 October. With USD-JPY pushing towards 150, intervention risk is increasing which explains why the exchange rate is no longer keeping pace with US yields. On the two previous occasions when Japan’s Ministry of Finance (MoF) intervened (September 2022 and October 2022), the JPY rallied by around 4% on the day. However, a lasting downward shift in USD would likely depend more on US yields than MoF tactics.',
		current: 'sideways',
		prev: 'sideways'
	},
	CHF: {
		text: 'While the Swiss National Bank (SNB) surprised with an unchanged policy rate at 1.75% in September, this should not be confused with a tolerance for CHF weakness. The SNB said that it cannot exclude future rate hikes and that it remains ready to intervene in the FX market, with the focus on selling foreign currencies for now. Apart from FX intervention risk, broader risk aversion on the back of global growth concerns and a renewed income squeeze from higher energy prices, could support the CHF over the near term, in our view.',
		current: 'down',
		prev: 'down'
	},
	CAD: {
		text: 'We look for USD-CAD to move lower in the run-up to the 25 October Bank of Canada meeting. Market pricing points to 11bp of tightening (Bloomberg, 27 September 2023), suggesting a finely balanced outcome. However, we believe the momentum is likely on the side of the policy hawks, with domestic economic data still delivering hawkish surprises. With rate differentials becoming less dominant, the CAD could also capitalise on elevated oil prices and North America specific risk appetite',
		current: 'down',
		prev: 'down'
	},
	AUD: {
		text: 'Limited improvements in China’s import demand for Australia’s iron ore and widening relative rate disadvantage versus the US could weigh on the AUD over the near term, in our view. The newly appointed Reserve Bank of Australia (RBA) governor, Michele Bullock, will helm her first meeting of the central bank on 3 October, which markets expect a status quo (Bloomberg, 27 September 2023).',
		current: 'down',
		prev: 'sideways'
	},
	NZD: {
		text: 'A more hawkish Reserve Bank of New Zealand (RBNZ), temporary stabilisation in dairy prices (given that dairy products being New Zealand’s largest goods exports), and potential expansionary fiscal measures around the general election on 14 October could buffer the USD strength for the NZD over the near term, in our view. While the RBNZ is likely to keep rates steady at its 4 October meeting, the New Zealand Q3 CPI print on 17 October could support a 25bp hike to 5.75% in November, in our economists’ view.',
		current: 'sideways',
		prev: 'down'
	}
};
