import { css, styled, typography } from 'utils/styles';

export const ResearchHeading = styled.h3(
	({ theme: { colors, fontWeight } }) => css`
		${typography.s4}
		font-weight: ${fontWeight.normal};
		position: relative;
		padding-left: 15px;
		margin-top: 0;

		&::after {
			content: '';
			width: 3px;
			height: 100%;
			background: ${colors.redHSBC};
			position: absolute;
			top: 0;
			left: 0;
		}
	`
);
