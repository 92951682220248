import React from 'react';
import { styled, mq, css } from 'utils/styles';
import { Flex } from 'components/common';
import { FxTrendsCcy, TacticalView } from 'common/research';
import { FxTrendsShortTermBox } from './FxTrendsShortTermBox';

interface FxTrendsTacticalViewProps {
	ccy: FxTrendsCcy;
	view: TacticalView;
}

const TacticalViewContainer = styled.div(
	({ theme: { colors, space } }) => css`
		flex-direction: column;
		margin-top: ${space.large};
		border-bottom: 1px solid ${colors.grey3};
	`,
	mq({
		display: ['flex', 'flex', 'flex', 'block']
	})
);

const Overview = styled.p`
	text-align: justify;
`;

export const FxTrendsTacticalView = (props: FxTrendsTacticalViewProps) => {
	const { ccy, view } = props;

	return (
		<TacticalViewContainer>
			<Flex gridGap="xxlarge">
				<FxTrendsShortTermBox ccy={ccy} current={view.current} previous={view.prev} />
				<Flex>{/*	todo: month button */}</Flex>
			</Flex>
			<Overview>{view.text}</Overview>
		</TacticalViewContainer>
	);
};
