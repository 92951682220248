import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Hero, UATOnly } from 'components/common';
import { FxRate, QueryParams } from 'types';
import { CurrencyZone } from '../CurrencyZone';
import { ResearchArticles } from '../ResearchArticles';
import { Research } from '../Research';
import { PRDOnly } from '../common/PRDOnly';

interface HomeProps {
	rate?: FxRate;
	query?: QueryParams;
}

export const Home = ({ rate, query }: HomeProps) => {
	return (
		<Grid>
			<Helmet
				meta={[
					{ name: 'robots', content: 'index, follow' },
					{ name: 'robots', content: 'max-snippet:50' }
				]}
			/>
			<Hero />
			<CurrencyZone rate={rate} query={query} />
			<PRDOnly>
				<ResearchArticles />
			</PRDOnly>
			<UATOnly>
				<Research />
			</UATOnly>
		</Grid>
	);
};

export default Home;
